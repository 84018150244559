import { inject } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';
import { catchError, distinctUntilChanged, EMPTY, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import type { UserContextGetResponseType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { HttpClient } from '@angular/common/http';
import { TenantService } from '../tenant/tenant.service';
import { StorageService } from '../storage/storage.service';
import { toSignal } from '@angular/core/rxjs-interop';

export const UserContextService = createInjectable(
  () => {
    const http = inject(HttpClient);
    const tenantService = inject(TenantService);
    const storageService = inject(StorageService);

    const userContext$ = storageService.watch('token').pipe(
      filter(Boolean),
      distinctUntilChanged(),
      switchMap(() =>
        http.get<UserContextGetResponseType>('/api/user/context').pipe(
          map((res) => ({
            userPermissions: res.user.permissions,
            tenantConfig: res.tenant,
            userProfile: {
              firstName: res.user.firstName,
              lastName: res.user.lastName,
              email: res.user.email,
            },
            dataplaneVersion: res.dataplaneVersion,
            possibleTenants: res.possibleTenants,
            isTenantOnboarded: res.isTenantOnboarded,
            credentials: res.credentials,
            couldUpgradeDataplane: res.user.permissions.includes('SETUP_WRITE')
              ? res.couldUpgradeDataplane
              : false,
            latestDataplaneTag: res.user.permissions.includes('DATAPLANE_CONFIGURATION_WRITE')
              ? res.latestDataplaneTag
              : undefined,
          })),
          tap(({ tenantConfig }) => (tenantService.currentTenant = tenantConfig?.tenantId)),
          catchError(() => EMPTY),
        ),
      ),
      shareReplay(1),
    );

    return {
      userProfile: toSignal(userContext$.pipe(map((ctx) => ctx.userProfile))),
      userPermissions: toSignal(userContext$.pipe(map((ctx) => ctx.userPermissions))),
      tenantConfig: toSignal(userContext$.pipe(map((ctx) => ctx.tenantConfig))),
      dataplaneVersion: toSignal(userContext$.pipe(map((ctx) => ctx.dataplaneVersion))),
      possibleTenants: toSignal(userContext$.pipe(map((ctx) => ctx.possibleTenants)), { initialValue: [] }),
      isTenantOnboarded: toSignal(userContext$.pipe(map((ctx) => ctx.isTenantOnboarded))),
      credentials: toSignal(userContext$.pipe(map((ctx) => ctx.credentials))),
      couldUpgradeDataplane: toSignal(userContext$.pipe(map((ctx) => ctx.couldUpgradeDataplane))),
      latestDataplaneTag: toSignal(userContext$.pipe(map((ctx) => ctx.latestDataplaneTag))),
    };
  },
  { providedIn: 'root' },
);
