import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { shared } from '@tig-dpqa-cloud/contract-backend-common';

@Pipe({
  name: 'username',
  standalone: true,
})
export class UserNamePipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(value: Partial<shared.UserType> | undefined): string {
    if (!value) {
      return this.translateService.instant('COMMON.NOT_AVAILABLE');
    }
    const { firstName, lastName } = value;
    return (firstName || lastName)
      ? `${firstName || ''} ${lastName || ''}`.trim()
      : this.translateService.instant('COMMON.NOT_AVAILABLE');
  }
}
