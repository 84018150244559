import { createInjectable } from 'ngxtension/create-injectable';
import { type AbstractStorageType } from './abstract-storage.service';
import { BehaviorSubject } from 'rxjs';

const tryParse = <T>(value: string): T | string | undefined => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const StorageService = createInjectable<() => AbstractStorageType>(
  () => {
    const storageData: Map<string, BehaviorSubject<unknown>> = new Map();
    const storageToUse: Storage = localStorage;

    Object.keys(storageToUse).forEach((key) => {
      const data = storageToUse.getItem(key);
      storageData.set(key, new BehaviorSubject(data ? tryParse(data) : undefined));
    });

    return {
      watch: (key) => {
        if (!storageData.has(key)) {
          storageData.set(key, new BehaviorSubject<unknown>(undefined));
        }

        const item = storageToUse.getItem(key);
        const storageDataItem = storageData.get(key)!;

        storageDataItem.next(item === null ? undefined : tryParse(item));

        return storageDataItem;
      },
      set: (key, value) => {
        storageToUse.setItem(key, JSON.stringify(value));

        if (storageData.has(key)) {
          storageData.get(key)!.next(value);
        } else {
          storageData.set(key, new BehaviorSubject<unknown>(value));
        }
      },
      get: <T>(key: string): T | undefined => {
        const value = storageToUse.getItem(key);
        return value ? (tryParse(value) as T) : undefined;
      },
      remove: (key) => {
        storageToUse.removeItem(key);

        if (storageData.has(key)) {
          storageData.get(key)!.next(undefined);
        } else {
          storageData.set(key, new BehaviorSubject<unknown>(undefined));
        }
      },
      clear: () => storageToUse.clear(),
    };
  },
{ providedIn: 'root' },
);
